.personal-info-section{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
}
.headshot{
    margin-left: 5%;
}
.headshot img{
    height: 40vh;
    border-radius: 8px;
}
.personal-info{
    display: flex;
    flex-direction: column;
    margin-left:5%;
}

.personal-info h2{
    font-size: 10vh;
    margin-top: 0;
    margin-bottom: 0;
    
}
.personal-info p{
    font-size: 3vh;
}